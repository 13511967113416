<template>
  <div class="measure-list">
    <overlay
      v-if="routematch"
      position="left"
      :prompt-before-close="promptBeforeClose"
      @close="closeOverlay"
    >
      <div slot="content">
        <clinician-respondent-form-wrapper
          :cca-id="ccaId"
          :is-loading="loadingGetPatient"
          @created="addRespondentOpen=false"
        />
      </div>
    </overlay>
  </div>
</template>

<script>
import { Helpers } from '@/mixins/Helpers'
import Overlay from '@/components/clinician/Overlay'
import { mapGetters } from 'vuex'
import ClinicianRespondentFormWrapper from '@/components/clinician/patients/ClinicianRespondentFormWrapper'

export default {
  name: 'ClinicianRespondent',
  components: {
    Overlay,
    ClinicianRespondentFormWrapper
  },
  mixins: [Helpers],
  props: {},
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      loadingGetPatient: 'getLoadingGetPatient'
    }),
    ccaId () {
      return this.$getDecodedId(this.$route.params.ccaId)
    }
  },
  methods: {
    routematch () {
      return this.$route.name === 'ClinicianPatientAddRespondent'
    },
    closeOverlay () {
      this.$router.back()
    },
    promptBeforeClose () {
      const routeName = this.$route.name
      return new Promise((resolve) => {
        if (['ClinicianPatientAddRespondent'].includes(routeName)) {
          const promptOptions = {
            message: this.$t('overlayCancelAdd', { data: 'respondent' }),
            title: this.$t('areYouSureToExit'),
            okButton: this.$t('yesExit')
          }
          this.$promptBeforeAction(promptOptions, () => {
            resolve()
          })
        } else {
          resolve()
        }
      })
    }
  }
}
</script>
